import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import {
  B2BTextField,
  DesktopPopupModal,
  LoadingBunny,
  SubmitButton,
} from "halifax";
import H from "history";
import queryStringParser from "query-string";
import {
  B2B_PORTAL_AUTH_REDIRECT_TO,
  B2B_PORTAL_UNAUTHORIZED_PATH,
} from "redmond";

import { postLoginCode } from "../../api/v1/auth";
import { PATH_B2B_HOME } from "../../utils/paths";
import "./styles.scss";
import { getPathFromURL } from "@capone/common/src/utils/getPathFromURL";

const PROFILE_REF_ID_REGEX = /profileReferenceId=(.*)&/;

interface ILoadingPageProps {
  language: string;
  history: H.History;
}

const LoadingPage = (props: ILoadingPageProps) => {
  const { history } = props;
  const [newProfileRefId, setNewProfileRefId] = useState("");
  const [errModalOpen, setErrModalOpen] = useState(false);

  const redirectToSessionStorageUrl = (url: string) => {
    history.push(getPathFromURL(url));

    sessionStorage.removeItem(B2B_PORTAL_AUTH_REDIRECT_TO);
  };

  const AuthZCodeFlow = async (profileReferenceId: string) => {
    try {
      await postLoginCode({ profileReferenceId });
      const redirectTo = sessionStorage.getItem(B2B_PORTAL_AUTH_REDIRECT_TO);
      if (redirectTo) redirectToSessionStorageUrl(redirectTo);
      else history.push(PATH_B2B_HOME);
    } catch (err) {
      setErrModalOpen(true);
      console.log(err);
    }
  };

  /**
   * @description Reloads the current page with a new profile reference id.
   * @param {string} profileRefId The new ID to try
   */
  const tryNewProfileRefId = () => {
    if (newProfileRefId) {
      const { pathname, search: urlParams } = window.location;
      const [, oldRefId] = urlParams.match(
        PROFILE_REF_ID_REGEX
      ) as Array<string>;

      // oldRefId is the capture group in the regexp, (i.e. index 1 of match() return)
      if (oldRefId) {
        const newUrlParams = urlParams.replace(oldRefId, newProfileRefId);
        window.location.href = `${pathname}${newUrlParams}`;
      }
    }
  };

  useEffect(() => {
    const queryString = queryStringParser.parse(history.location.search);

    if (queryString.profileReferenceId) {
      AuthZCodeFlow(queryString.profileReferenceId as string);
    } else {
      history.push(B2B_PORTAL_UNAUTHORIZED_PATH);
    }
  }, []);

  return (
    <Box className="loading-page-container">
      {errModalOpen ? (
        <DesktopPopupModal
          aria-labelledby="bad-profile-ref-id"
          className="error-ref-id-modal"
          contentClassName="error-ref-id-modal-content"
          onClose={() => setErrModalOpen(false)}
          open={errModalOpen}
        >
          <Grid
            container
            alignItems="center"
            direction="column"
            justifyContent="space-around"
          >
            <Typography variant="h5">
              We couldn't find that <b>Profile Reference ID</b>. Try a different
              one?
            </Typography>
            <B2BTextField
              className="ref-id-input"
              label="Profile Reference ID"
              onChange={setNewProfileRefId}
              value={newProfileRefId}
            />
            <SubmitButton
              className="submit-ref-id"
              isActive={Boolean(newProfileRefId)}
              message="Submit"
              onClick={tryNewProfileRefId}
            />
          </Grid>
        </DesktopPopupModal>
      ) : (
        <LoadingBunny />
      )}
    </Box>
  );
};

export default LoadingPage;
