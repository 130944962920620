import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { LoadingBunny } from "halifax";
import { fetchLoginCode } from "../../api/v1/auth";
interface IUnauthorizedPageProps {
  language: string;
}

const UnauthorizedPage = (_props: IUnauthorizedPageProps) => {
  const handleUnauthorized = async () => {
    let response = await fetchLoginCode();
    window.location.replace(response.loginUri);
  };

  useEffect(() => {
    handleUnauthorized();
  }, []);

  return (
    <Box className="unauthorized-page-container">
      <LoadingBunny />
    </Box>
  );
};

export default UnauthorizedPage;
