import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { LoadingBunny } from "halifax";
import H from "history";
import { postLogout } from "../../api/v1/auth";

interface ILoadingPageProps {
  language: string;
  history: H.History;
}

const LogoutPage = (_props: ILoadingPageProps) => {
  const logout = async () => {
    try {
      const logoutResponse = await postLogout();
      if (logoutResponse.destinationUrl)
        window.location.replace(logoutResponse.destinationUrl);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <Box className="logout-page-container">
      <LoadingBunny />
    </Box>
  );
};

export default LogoutPage;
