import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core";
import React, { useState } from "react";
import { Router, Route } from "react-router-dom";
import useWindowEventListener from "./hooks/useWindowEventListener";
import { eventsToListen } from "./utils/events";
import { IntlProvider } from "react-intl";
import * as frenchTranslations from "./lang/fr.json";
import * as englishTranslations from "./lang/en.json";
import { AgentAuthModuleProps, installColorConfig } from "redmond";
import AxiosInterceptors from "./components/AxiosInterceptors";
import LoadingPage from "./components/LoadingPage";
import LogoutPage from "./components/LogoutPage";
import UnauthorizedPage from "./components/UnauthorizedPage";
import {
  PATH_START_SESSION,
  PATH_INVALID_SESSION,
  PATH_END_SESSION,
} from "./utils/paths";

function loadLocaleData(locale: string): any {
  switch (locale) {
    case "fr":
      return frenchTranslations;
    default:
      return englishTranslations;
  }
}
const generateClassName = createGenerateClassName({
  productionPrefix: "agentAuthModule",
  seed: "agentAuthModule",
});

const App = (props: AgentAuthModuleProps) => {
  const { isAgentPortal, theme, language, colors, baseHistory } = props;

  const [activeTheme, setActiveTheme] = useState(theme);
  const [locale, setLocale] = useState(language);
  const [messages, setMessages] = useState(loadLocaleData(locale).default);

  installColorConfig(colors);

  const handleThemeChanged = (e: CustomEvent) => {
    setActiveTheme(e.detail);
    console.log("THEME RECEIVED:", e.detail.palette.type);
  };
  const handleLocaleChanged = (e: CustomEvent) => {
    setLocale(e.detail);
    const messages = loadLocaleData(e.detail);
    setMessages(messages.default);
  };
  useWindowEventListener(eventsToListen.HOST_THEME_CHANGED, handleThemeChanged);
  useWindowEventListener(
    eventsToListen.HOST_LOCALE_CHANGED,
    handleLocaleChanged
  );

  return (
    <Router history={baseHistory}>
      <AxiosInterceptors isAgentPortal={isAgentPortal}>
        <div className="App">
          <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={activeTheme}>
              {messages != null ? (
                <IntlProvider
                  locale={locale}
                  defaultLocale="en"
                  messages={messages}
                >
                  <Route
                    path={PATH_START_SESSION}
                    render={(props) => (
                      <LoadingPage {...props} language={language} />
                    )}
                  />
                  <Route
                    path={PATH_END_SESSION}
                    render={(props) => (
                      <LogoutPage {...props} language={language} />
                    )}
                  />
                  <Route
                    path={PATH_INVALID_SESSION}
                    render={(props) => (
                      <UnauthorizedPage {...props} language={language} />
                    )}
                  />
                </IntlProvider>
              ) : (
                <div>Loading</div>
              )}
            </ThemeProvider>
          </StylesProvider>
        </div>
      </AxiosInterceptors>
    </Router>
  );
};

export default App;
