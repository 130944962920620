import axios from "axios";
import { config } from "../../config";
import { loginAgentApiPath } from "../paths";
import { AgentLoginProfileRefIdPostRequest } from "redmond";

export const postLoginCode = (
  body: AgentLoginProfileRefIdPostRequest
): Promise<{}> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(loginAgentApiPath, body, {
        baseURL: config.baseURL,
      });
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
